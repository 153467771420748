import React from 'react';
import './App.css';
import Axios from "axios"
//import "./bootstrap.css";
import MainContainer from './elements/maincontainer';
import NetworkTable from './elements/networktable';
import BscTable from './elements/tokentables/bsctable';
import EthTable from './elements/tokentables/ethtable';
import AvaxTable from './elements/tokentables/avaxtable'
import ArbitrumTable from './elements/tokentables/arbitrumtable';
import AuroraTable from './elements/tokentables/auroratable';
import CantoTable from './elements/tokentables/cantotable';
import CronosTable from './elements/tokentables/cronostable';
import DogechainTable from './elements/tokentables/dogechaintable';
import EtcTable from './elements/tokentables/etctable';
import EthfairTable from './elements/tokentables/ethfairtable';
import EthpowTable from './elements/tokentables/ethpowtable';
import FtmTable from './elements/tokentables/ftmtable';
import HarmonyTable from './elements/tokentables/harmonytable';
import KekchainTable from './elements/tokentables/kekchaintable';
import MetisTable from './elements/tokentables/metistable';
import OptimismTable from './elements/tokentables/optimismtable';
import PolygonTable from './elements/tokentables/polygontable';
import RedlightTable from './elements/tokentables/redlighttable';
import TelosTable from './elements/tokentables/telostable';

// POTENTIAL FEATURES A SEARCHBAR TO FIND TOKENS BY NAME 

function App() {



  // BSC LIST
  const [tokenList, setTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensbsc").then((response) => {
      setTokenList(response.data)
    })
  },[])


  // ETH LIST 
  const [ethTokenList, setEthTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokenseth").then((response) => {
      setEthTokenList(response.data)
    })
  },[])
  
  

  // AVAX LIST 
  const [avaxTokenList, setAvaxTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensavax").then((response) => {
      setAvaxTokenList(response.data)
    })
  },[])

  // ARBITRUM LIST 
  const [arbitrumTokenList, setArbitrumTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensarbitrum").then((response) => {
      setArbitrumTokenList(response.data)
    })
  },[])

  // AURORA LIST 
  const [auroraTokenList, setAuroraTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensaurora").then((response) => {
      setAuroraTokenList(response.data)
    })
  },[])

  // CANTO LIST 
  const [cantoTokenList, setCantoTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokenscanto").then((response) => {
      setCantoTokenList(response.data)
    })
  },[])
 
  // CRONOSLIST 
  const [cronosTokenList, setCronosTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokenscronos").then((response) => {
      setCronosTokenList(response.data)
    })
  },[])

  // DOGECHAIN LIST 
  const [dogechainTokenList, setDogechainTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensdogechain").then((response) => {
      setDogechainTokenList(response.data)
    })
  },[])

  // ETC LIST 
  const [etcTokenList, setEtcTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensetc").then((response) => {
      setEtcTokenList(response.data)
    })
  },[])

  // ETH FAIR LIST 
  const [ethfairTokenList, setEthfairTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensethfair").then((response) => {
      setEthfairTokenList(response.data)
    })
  },[])

  // ETHPOW LIST 
  const [ethpowTokenList, setEthpowTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensethpow").then((response) => {
      setEthpowTokenList(response.data)
    })
  },[])

  // FTM LIST 
  const [ftmTokenList, setFtmTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensftm").then((response) => {
      setFtmTokenList(response.data)
    })
  },[])
  
  // HARMONY LIST 
  const [harmonyTokenList, setHarmonyTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensharmony").then((response) => {
      setHarmonyTokenList(response.data)
    })
  },[])

  // KEKCHAIN LIST 
  const [kekchainTokenList, setKekchainTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokenskekchain").then((response) => {
      setKekchainTokenList(response.data)
    })
  },[])

  // METIS LIST 
  const [metisTokenList, setMetisTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensmetis").then((response) => {
      setMetisTokenList(response.data)
    })
  },[])

  // OPTIMISM LIST 
  const [optimismTokenList, setOptimismTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensoptimism").then((response) => {
      setOptimismTokenList(response.data)
    })
  },[])

  // POLYGON LIST 
  const [polygonTokenList, setPolygonTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokenspolygon").then((response) => {
      setPolygonTokenList(response.data)
    })
  },[])

  // REDLIGHT LIST 
  const [redlightTokenList, setRedlightTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokensredlight").then((response) => {
      setRedlightTokenList(response.data)
    })
  },[])

  // TELOS LIST 
  const [telosTokenList, setTelosTokenList] = React.useState([])
  React.useEffect(()=> {
    Axios.get("https://api-0xscan.herokuapp.com/gettokenstelos").then((response) => {
      setTelosTokenList(response.data)
    })
  },[])

  const [showTable, setShowTable] = React.useState(4)
  function setTable(num) {
    setShowTable(num)
  }  
  
  function refresh(){
    Axios.get("http://localhost:3001/gettokensbsc").then((response) => {
      setTokenList(response.data)
    })
  }

  return (
    <div className="App">
      <div className='overallContainer'>
        

        <MainContainer 
          refresh= {refresh}
          left = {<NetworkTable showState= {showTable} func={setTable}  />} 
          right = { <>
                      <EthTable  list= {ethTokenList} state={showTable} />
                      <BscTable  list= {tokenList} state={showTable}/>
                      <AvaxTable list= {avaxTokenList} state={showTable}/>
                      <ArbitrumTable list= {arbitrumTokenList} state={showTable}/>
                      <AuroraTable list={auroraTokenList} state={showTable}/>
                      <CantoTable list={cantoTokenList} state={showTable}/>
                      <CronosTable list= {cronosTokenList} state={showTable}/>
                      <DogechainTable list={dogechainTokenList} state={showTable}/>
                      <EtcTable list= {etcTokenList} state={showTable}/>
                      <EthfairTable list = {ethfairTokenList} state={showTable}/>
                      <EthpowTable list= {ethpowTokenList} state={showTable}/>
                      <FtmTable list={ftmTokenList} state={showTable}/>
                      <HarmonyTable list={harmonyTokenList} state={showTable}/>
                      <KekchainTable list={kekchainTokenList} state={showTable}/>
                      <MetisTable list = {metisTokenList} state={showTable}/>
                      <OptimismTable list= {optimismTokenList} state={showTable}/>
                      <PolygonTable list= {polygonTokenList} state={showTable}/>
                      <RedlightTable list={redlightTokenList} state={showTable}/>
                      <TelosTable list = {telosTokenList} state={showTable}/>
                    </>} 
                  />
         
      </div> 
    </div>
  );
}

export default App;
