import React from "react"
import logo from "../images/logo.png"

export default function MainContainer(props) {
    return (
    <div className='mainContainer '>
        <div className="navbar">
            <img className="logo" src={logo} />
            
        </div>
        <div className='mainrow '>
            <div className='tabContainer'>
                {/* table of networks */}
                {props.left}
            </div>

            <div className='listContainer'>
                {/* Table of tokens */}
                {props.right}
            </div>
            <button onClick={props.refresh}>Refresh</button>
        </div>
        
    </div>
    
    )
}