import React from "react";
import short from "../shortaddress"
import age from "../secondsToHms"

export default function EthTable(props) {
    return (
        <table className={props.state === 11 ? 'table' : 'table hidden'} id="ethtable">
                <thead>
                <td>Name</td>
                <td>Symbol</td>
                <td>Address</td>
                <td>Explorer</td>
                <td>Age</td>
                </thead>
                <tbody>

                    {props.list.map(token=> (
                    <tr>
                        <td>{token.name}</td>
                        <td>{token.symbol}</td>
                        <td>{short(token.address)}</td>
                        <td><a href={`https://etherscan.io/token/${token.address}`} target="_blank" rel="noreferrer">Etherscan</a></td>
                        <td>{age( (Date.now() - token.time) / 1000 )}</td>
                        {/* {age( (Date.now() - token.time) / 1000 )} */}
                    </tr>))}
               
                </tbody>
            </table>
    )
}