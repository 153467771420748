import React from "react";
import bscscanlogo from "../images/bscscan-logo-circle.png"

export default function NetworkTable(props) {
    return (
        <table className='table'>
              <thead>
                <tr className= { props.showState === 4  ?"active " : ""}  onClick={ () => props.func(4)} ><td>Binance Smart Chain</td></tr>
                <tr className= { props.showState === 11 ?"active" : ""}  onClick={ () => props.func(11)} ><td>Ethereum</td></tr>
                <tr className= { props.showState === 17 ?"active" : ""} onClick={ () => props.func(17)} ><td>Polygon</td></tr>
                <tr className= { props.showState === 1 ?"active" : ""} onClick={ () => props.func(1)} ><td>Arbitrum</td></tr>
                <tr className= { props.showState === 16 ?"active" : ""} onClick={ () => props.func(16)} ><td>Optimism</td></tr>
                <tr className= { props.showState === 19 ?"active" : ""} onClick={ () => props.func(19)} ><td>Telos</td></tr>
                <tr className= { props.showState === 3 ?"active" : ""} onClick={ () => props.func(3)} ><td>Avax</td></tr>
                <tr className= { props.showState === 2 ?"active" : ""} onClick={ () => props.func(2)} ><td>Aurora</td></tr>
                <tr className= { props.showState === 12 ?"active" : ""} onClick={ () => props.func(12)} ><td>Ftm</td></tr>
                <tr className= { props.showState === 6 ?"active" : ""} onClick={ () => props.func(6)} ><td>Cronos</td></tr>
                <tr className= { props.showState === 5 ?"active" : ""} onClick={ () => props.func(5)} ><td>Canto</td></tr>
                <tr className= { props.showState === 7 ?"active" : ""} onClick={ () => props.func(7)} ><td>Dogechain</td></tr>
                <tr className= { props.showState === 8 ?"active" : ""} onClick={ () => props.func(8)} ><td>Ethereum Classic</td></tr>
                <tr className= { props.showState === 9 ?"active" : ""} onClick={ () => props.func(9)} ><td>Ethereum Fair</td></tr>
                <tr className= { props.showState === 10 ?"active" : ""} onClick={ () => props.func(10)} ><td>Ethereum Pow</td></tr>
                <tr className= { props.showState === 13 ?"active" : ""} onClick={ () => props.func(13)} ><td>Harmony</td></tr>
                <tr className= { props.showState === 14 ?"active" : ""} onClick={ () => props.func(14)} ><td>Kekchain</td></tr>
                <tr className= { props.showState === 15 ?"active" : ""} onClick={ () => props.func(15)} ><td>Metis</td></tr>
                <tr className= { props.showState === 18 ?"active" : ""} onClick={ () => props.func(18)} ><td>Redlight</td></tr>
              </thead>
            </table>
    )
} 